// Here you can add other styles
.c-body {
    backgroud-color: white;
}

.apend-logo {
    background-color: #347;
    color: white;
}

.display-5 {
    font-size: 2.6em;
}


.display-6 {
    font-size: 1.3em;
}